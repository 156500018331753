import URL from 'url-parse';

const SCRIPT_SRC = 'storefront.js';
const ACCOUNT_PARAM = 'id';
const BANNER_VERSION = 'bv';
const CODE_VERSION = 'cv';
const DEBUG_PARAM = 'debug';

export default class Account {
	constructor() {
		const scripts = document.querySelectorAll(`script[src*='${SCRIPT_SRC}']`);
		for (let i = 0; i < scripts.length; i++) {
			const script = scripts[i];
			const scriptURL = new URL(script.getAttribute('src'), window.location, true);
			if (!scriptURL.query || !scriptURL.query[ACCOUNT_PARAM]) {
				continue;
			}
			this.accountId = scriptURL.query[ACCOUNT_PARAM];
			this.bannerVersion = scriptURL.query[BANNER_VERSION];
			this.codeVersion = scriptURL.query[CODE_VERSION];
			this.debugEnabled = scriptURL.query[DEBUG_PARAM] === 'true';
			const url = new URL(window.location.href, window.location, true);
			this.debugEnabled = this.debugEnabled || (url.query && url.query[DEBUG_PARAM] === 'true');
		}
	}

	isAccountAvailable() {
		return this.accountId !== undefined && this.accountId !== null;
	}

	isDebugEnabled() {
		return this.debugEnabled;
	}

	getAccountId() {
		return this.accountId;
	}

	getBannerVersion() {
		return this.bannerVersion;
	}

	getCodeVersion() {
		return this.codeVersion;
	}
}
