import Storage from './storage';

const getParameterByName = name => {
	const url = window.location.href;
	name = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const CUSTOMER_LAST_SUBSCRIBED_KEY = 'seguno_last_sub';
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60;
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60;
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24;
const DAYS_TO_EXPIRE = 90;
const MILLIS_TO_EXPIRE = DAYS_TO_EXPIRE * MILLIS_PER_DAY;

export default class Customer {
	constructor() {
		this.storage = new Storage();
		if (getParameterByName('utm_source') === 'seguno') {
			this.setCustomerSubscribed();
		}
		const customerSubscribedDate = this.getCustomerSubscribedDate();
		const now = new Date();
		if (customerSubscribedDate && now.getTime() - customerSubscribedDate.getTime() >= MILLIS_TO_EXPIRE) {
			this.expireCustomerSubscribed();
		}
		this.customerSubscribed = this.getCustomerSubscribedDate() !== null || window.location.pathname === '/challenge';
		this.customerJustSubscribed = getParameterByName('customer_posted') === 'true';
		if (this.isCustomerJustSubscribed()) {
			this.setCustomerSubscribed();
		}
	}

	getCustomerSubscribedDate() {
		return this.storage.hasItem(CUSTOMER_LAST_SUBSCRIBED_KEY) ? new Date(Number(this.storage.getItem(CUSTOMER_LAST_SUBSCRIBED_KEY))) : null;
	}

	setCustomerSubscribed() {
		this.storage.setItem(CUSTOMER_LAST_SUBSCRIBED_KEY, new Date().getTime().toString());
	}

	expireCustomerSubscribed() {
		this.storage.removeItem(CUSTOMER_LAST_SUBSCRIBED_KEY);
	}

	isCustomerJustSubscribed() {
		return this.customerJustSubscribed;
	}

	isCustomerSubscribed() {
		return this.customerSubscribed;
	}
}
