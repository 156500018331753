import Storage from './storage';
import URL from 'url-parse';

const DISCOUNT_PARAM = 'discount';
const PREFIX = '__seguno-';
const STORAGE_KEY = `${PREFIX}dc`;

function getProvidedDiscount() {
	const url = new URL(window.location.href, window.location, true);
	const discount = url.query && url.query[DISCOUNT_PARAM];
	if (discount && discount.trim().length > 0) {
		return discount;
	}
	return null;
}

export default class Discount {
	constructor(code, saveCode = true) {
		this.storage = new Storage();
		const oldCode = this.storage.getItem(STORAGE_KEY);
		this.code = code || getProvidedDiscount() || oldCode;
		if (this.code && saveCode) {
			this.storage.setItem(STORAGE_KEY, this.code);
		} else {
			this.storage.removeItem(STORAGE_KEY);
		}
		this.isNewCode = saveCode && oldCode !== this.code;
	}

	markRedeemed() {
		this.markUnavailable();
	}

	markUnavailable() {
		this.storage.removeItem(STORAGE_KEY);
		this.code = null;
		this.isNewCode = false;
	}

	isAvailable() {
		return this.code !== undefined && this.code !== null;
	}

	isNew() {
		return this.isNewCode;
	}

	getCode() {
		return this.code;
	}
}
