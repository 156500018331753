const LS_TEST_ITEM = 'seguno-ls-test';

export default class Storage {
	constructor(isSession) {
		this.storageEngine = isSession ? sessionStorage : localStorage;
	}

	isStorageSupported() {
		try {
			this.storageEngine.setItem(LS_TEST_ITEM, LS_TEST_ITEM);
			this.storageEngine.removeItem(LS_TEST_ITEM);
			return true;
		} catch (e) {
			return false;
		}
	}

	hasItem(key) {
		return this.storageEngine.getItem(key) !== null;
	}

	setItem(key, value) {
		if (!this.isStorageSupported()) {
			return;
		}
		this.storageEngine.setItem(key, value);
	}

	getItem(key, defaultValue = null) {
		if (!this.isStorageSupported()) {
			return defaultValue;
		}
		return this.storageEngine.getItem(key);
	}

	removeItem(key) {
		if (!this.isStorageSupported()) {
			return;
		}
		return this.storageEngine.removeItem(key);
	}
}
