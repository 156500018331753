import Storage from './storage';

const MILLIS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const DEFAULT_EXPIRE = 5 * SECONDS_PER_MINUTE * MILLIS_PER_SECOND;
const CACHE_KEY_PREFIX = '__seguno-cache-key-';

export default class VersionCache {
	constructor(expireMillis = DEFAULT_EXPIRE) {
		this.storage = new Storage(true);
		this.expireMillis = expireMillis;
	}

	getVersion(key) {
		const cacheKey = CACHE_KEY_PREFIX + key;
		const nowInMillis = new Date().getTime();
		const currentValue = this.storage.getItem(cacheKey);
		const needsNewKey = !currentValue || Number(currentValue) + this.expireMillis < nowInMillis;
		if (needsNewKey) {
			this.storage.setItem(cacheKey, nowInMillis);
			return nowInMillis;
		}
		return currentValue;
	}
}
