import events from './events';

const FINAL_CHECKOUT_STEP = 'thank_you';

export default class ConversionTracker {
	dispatchConversionEvent(order) {
		const detail = {
			order
		};
		document.dispatchEvent(new CustomEvent(events.CONVERSION, { detail }));
	}

	checkForConversion() {
		const shopifyObject = window.Shopify;
		if (shopifyObject) {
			const { Checkout, checkout } = shopifyObject;
			if (Checkout && Checkout.step === FINAL_CHECKOUT_STEP && checkout) {
				const conversionEvent = {
					id: checkout.order_id,
					customer: checkout.customer_id,
					email: checkout.email,
					discount: checkout.discount && checkout.discount.code
				};
				this.dispatchConversionEvent(conversionEvent);
				return conversionEvent;
			}
		}
		return null;
	}
}
