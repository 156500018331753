import Storage from './storage';

const AUTO_APPLY_KEY = '__seguno_dc_aa';

export default class AutoApply {
	constructor() {
		this.storage = new Storage(true);
	}

	isAutoApplied(discountCode) {
		return this.storage.getItem(AUTO_APPLY_KEY) === discountCode;
	}

	applyCode(discountCode) {
		if (this.isAutoApplied(discountCode)) {
			return;
		}
		fetch(`/discount/${encodeURIComponent(discountCode)}`, { credentials: 'include' }).then(response => {
			if (response.ok) {
				this.storage.setItem(AUTO_APPLY_KEY, discountCode);
			}
		});
	}
}
