export default class Logger {
	constructor(account) {
		this.debugEnabled = account.isDebugEnabled();
	}

	log(...args) {
		if (this.debugEnabled) {
			console.log('SEGUNO DEBUG: ', ...args);
		}
	}
}
