const fetchCart = () => {
	try {
		return fetch('/cart.js', { credentials: 'include' }).then(response => {
			if (response.ok) {
				return Promise.resolve(response.json());
			} else {
				return Promise.reject(response.statusText);
			}
		});
	} catch (e) {
		return Promise.reject(e);
	}
};

export default class Cart {
	constructor() {
		this.cart = null;
	}

	getCart(reload = false) {
		if (reload) {
			this.cart = null;
		}
		if (this.cart) {
			return Promise.resolve(this.cart);
		}
		return fetchCart().then(cart => {
			this.cart = cart;
			return cart;
		});
	}

	listen(callback) {
		const performCallbackIfUrlMatches = (data = {}) => {
			const { url } = data;
			if (!url) {
				return;
			}
			if (
				url.indexOf('/cart/add.js') !== -1 ||
				url.indexOf('/cart/change.js') !== -1 ||
				url === '/cart/update.js' ||
				url.indexOf('/cart/add') !== -1 ||
				url.indexOf('/cart/change') !== -1 ||
				url === '/cart/update'
			) {
				this.cart = null;
				callback();
			}
		};
		if (window.jQuery) {
			window.jQuery(document).ajaxSuccess((event, options, data) => performCallbackIfUrlMatches(data));
		}
		if (typeof window.fetch === 'function') {
			const originalFetch = window.fetch;
			window.fetch = function () {
				return originalFetch.apply(this, arguments).then(data => {
					try {
						performCallbackIfUrlMatches(data);
					} catch (e) {
						// Ignore errors and allow regular fetch to proceed
					}
					return data;
				});
			};
		}
	}
}
